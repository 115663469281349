import urbaneaseLogo from '../../images/urbaneaseLogo.png';
import Modal from '../Modals/Modal';
import styles from './linksToUrbanease.module.scss';

interface INoUrbaneaseAccountModalProps {
  errorStatus: number | null;
}
function NoUrbaneaseAccountModal({ errorStatus }: INoUrbaneaseAccountModalProps) {
  const handleClick = () => {
    window.open(
      'https://meetings-eu1.hubspot.com/teo-laurent',
      '_blank',
      'external nofollow noreferrer'
    );
  };

  const handleSupportClick = () => {
    window.open(
      'mailto:support@urbanease.io',
      '_blank',
      'external nofollow noreferrer'
    );
  };

  return (
    <Modal>
      <div className={styles.noUrbaneaseAccountModal}>
        <a
          href="https://urbanease.io"
          target="_blank"
          rel="nofollow noreferrer"
          className={styles.logo}
        >
          <img src={urbaneaseLogo} alt="logo urbanease" />
        </a>
        <h1>Accès à Urbanease</h1>
        {errorStatus === 403 && (
          <>
            <p>
              Votre compte ne vous donne actuellement pas accès à notre application
              Urbanease.
            </p>
            <p>
              Découvrez{' '}
              <a
                href="https://urbanease.io"
                target="_blank"
                rel="nofollow noreferrer"
              >
                Urbanease
              </a>{' '}
              - la plateforme cartographique de prospection utilisée par des milliers
              de professionnels en France
            </p>

            <iframe
              width="90%"
              height="400"
              src="https://www.youtube.com/embed/TJrkcnLsUVQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <p>
              Vous pouvez prendre un rendez-vous pour une démo gratuite dès
              maintenant.
            </p>

            <button type="button" onClick={handleClick}>
              Je prends rendez-vous
            </button>
          </>
        )}
        {errorStatus !== 403 && (
          <>
            <p>
              La connexion avec l&#039;application Urbanease est actuellement
              impossible. <br />
              Si le problème persiste, veuillez contacter le support
              d&#039;Urbanease.
            </p>
            <button type="button" onClick={handleSupportClick}>
              Je contact le support
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default NoUrbaneaseAccountModal;
