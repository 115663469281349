import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activeResearchesActions } from '../../../features/activeResearch/actions';
import Button from '../../Assets/Button';
import LogoButton from '../../Assets/LogoButton';
import SwitchButton from '../../Forms/SwitchButton';

const CardFooter = ({
  idIri,
  isAlertEnabled,
  cities,
}: Pick<IActiveResearch, 'idIri' | 'cities' | 'isAlertEnabled'>) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const id = idIri.split('/')[2];

  const handleSwitchAlertUpdate = () => {
    dispatch(
      activeResearchesActions.activeResearchAlertUpdate.request({
        idIri,
        body: { isAlertEnabled: !isAlertEnabled },
      })
    );
  };

  return (
    <footer className="active-search-card-footer">
      <div className="active-search-card-footer-elt">
        <SwitchButton
          checked={isAlertEnabled}
          handleChange={handleSwitchAlertUpdate}
          name="alert"
          label="Alerte"
          labelPlacement="start"
        />
      </div>
      <div className="active-search-card-footer-btns">
        <Button
          btnType="button"
          btnContent="Consulter"
          primary
          handleClick={() => history.push(`/recherche-active-resultat/${id}`)}
          disabled={isEmpty(cities) ? true : false}
        />
        <LogoButton
          btnType="button"
          btnLogo="pen-violet"
          tooltip={`Modifier`}
          handleClick={() => history.push(`/recherche-active-modification/${id}`)}
        />
        <LogoButton
          btnType="button"
          btnLogo="delete-red"
          tooltip={`Supprimer`}
          handleClick={() =>
            dispatch(activeResearchesActions.deleteActiveResearch.request(idIri))
          }
        />
      </div>
    </footer>
  );
};

CardFooter.propTypes = {
  idIri: PropTypes.string,
  isAlertEnabled: PropTypes.bool,
  cities: PropTypes.array,
};
export default CardFooter;
